html,
body {
    overflow-x: hidden; /* Prevent scroll on narrow devices */
}

body {
    padding-top: 56px;
    /*background-image: url('/images/MBmedien_Background_1920x972.png');*/
    background-color: #ffffff;
}

@media (max-width: 767.98px) {
    .offcanvas-collapse {
        position: fixed;
        top: 56px; /* Height of navbar */
        bottom: 0;
        width: 100%;
        padding-right: 1rem;
        padding-left: 1rem;
        overflow-y: auto;
        background-color: var(--white);
        transition: -webkit-transform .3s ease-in-out;
        transition: transform .3s ease-in-out;
        transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out;
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
    }

        .offcanvas-collapse.open {
            -webkit-transform: translateX(-1rem);
            transform: translateX(-1rem); /* Account for horizontal padding on navbar */
        }
}

.nav-scroller {
    position: relative;
    z-index: 2;
    height: 2.75rem;
    overflow-y: hidden;
}

    .nav-scroller .nav {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        padding-bottom: 1rem;
        margin-top: -1px;
        overflow-x: auto;
        color: rgba(255, 255, 255, .75);
        text-align: center;
        white-space: nowrap;
        -webkit-overflow-scrolling: touch;
    }

.nav-underline .nav-link {
    padding-top: .75rem;
    padding-bottom: .75rem;
    font-size: .875rem;
    color: var(--secondary);
}

    .nav-underline .nav-link:hover {
        color: var(--blue);
    }

.nav-underline .active {
    font-weight: 500;
    color: var(--gray-dark);
}

.text-white-50 {
    color: rgba(255, 255, 255, .5);
}

.bg-purple {
    background-color: var(--purple);
}

.bg-gray {
    background-color: #eaeaea;
}

.border-bottom {
    border-bottom: 1px solid #e5e5e5;
}

.box-shadow {
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.lh-100 {
    line-height: 1;
}

.lh-125 {
    line-height: 1.25;
}

.lh-150 {
    line-height: 1.5;
}

.navbar-brand > img {
    max-width: 200px;
}

.m-t-10 {
    margin-top: 10px;
}

.m-t-20 {
    margin-top: 20px;
}

.m-t-30 {
    margin-top: 30px;
}

.p-t-10 {
    padding-top: 10px;
}

.p-t-20 {
    padding-top: 20px;
}

.p-t-30 {
    padding-top: 30px;
}

.bg-light {
    background-color: #fff;
}

.footer {
    margin-top: 10px;
}

.text-item {
    font-size: 14px;
    color: #595959;
    font-weight: 600;
}

.text-description {
    font-size: 12px;
    color: #595959;
    font-weight: 400;
}

.topic-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 0.4rem;
    width: 100%;
    height: 48px;
    min-width: 150px;
}

.topic-switches p {
    color: rgba(0, 0, 16, 0.8);
    font-size: 1.3rem;
    padding: 0.3rem;
}

.topics-switches {
    background-color: rgba(230, 230, 230, 0.8);
    border-radius: 2.2rem;
    border: 1px solid #d7d7d7;
}

.aboBlocks-switches p {
    color: rgba(0, 0, 16, 0.8);
    font-size: 1.3rem;
    padding: 0.3rem;
}

.aboBlocks-switches {
    background-color: rgba(230, 230, 230, 0.8);
    border-radius: 2.2rem;
    border: 1px solid #d7d7d7;
}

svg {
    fill: #353535;
    height: 1.8rem;
    width: 1.8rem;
    margin: 0.2rem;
}


#radios label {
    position: relative;
}

input[type="radio"] {
    position: absolute;
    opacity: 0;
}

    input[type="radio"] + svg {
        -webkit-transition: all 0.2s;
        transition: all 0.2s;
    }

input + svg {
    cursor: pointer;
}



input[class="subscribe"]:hover:not([disabled]) + svg,
input[class="subscribe"]:checked:not([disabled]) + svg,
input[class="subscribe"]:focus:not([disabled]) + svg {
    fill: rgb(0, 204, 79);
}


input[class="opt-in"]:hover:not([disabled]) + svg,
input[class="opt-in"]:checked:not([disabled]) + svg,
input[class="opt-in"]:focus:not([disabled]) + svg {
    fill: rgb(0, 204, 79);
}

input[class="neutral"]:hover:not([disabled]) + svg,
input[class="neutral"]:checked:not([disabled]) + svg,
input[class="neutral"]:focus:not([disabled]) + svg {
    fill: rgb(201, 161, 48);
}

input[class="unsubscribe"]:hover:not([disabled]) + svg,
input[class="unsubscribe"]:checked:not([disabled]) + svg,
input[class="unsubscribe"]:focus:not([disabled]) + svg {
    fill: rgb(239, 42, 16);
}

input[class="opt-out"]:hover:not([disabled]) + svg,
input[class="opt-out"]:checked:not([disabled]) + svg,
input[class="opt-out"]:focus:not([disabled]) + svg {
    fill: rgb(239, 42, 16);
}

input[class="unsubscribe"]:hover:disabled + svg,
input[class="neutral"]:hover:disabled + svg,
input[class="subscribe"]:hover:disabled + svg,
input[class="opt-in"]:hover:disabled + svg,
input[class="opt-out"]:hover:disabled + svg, {
    cursor: not-allowed !important;
}

.vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}


@keyframes check {
    0% {
        height: 0;
        width: 0;
    }

    25% {
        height: 0;
        width: 10px;
    }

    50% {
        height: 20px;
        width: 10px;
    }
}

.checkbox {
    background-color: #fff;
    display: inline-block;
    height: 28px;
    margin-right: .75rem;
    width: 28px;
    padding-right: 20px;
    border-radius: 4px;
    border: 1px solid #ccc;
    float: left;
}


    .checkbox span {
        display: block;
        height: 28px;
        position: relative;
        width: 28px;
        padding: 0
    }

.checkbox-desc {
    font-size: 14px;
    position: absolute;
    top: 4px;
}

.checkbox span:after {
    -moz-transform: scaleX(-1) rotate(135deg);
    -ms-transform: scaleX(-1) rotate(135deg);
    -webkit-transform: scaleX(-1) rotate(135deg);
    transform: scaleX(-1) rotate(135deg);
    -moz-transform-origin: left top;
    -ms-transform-origin: left top;
    -webkit-transform-origin: left top;
    transform-origin: left top;
    border-right: 4px solid #fff;
    border-top: 4px solid #fff;
    content: '';
    display: block;
    height: 20px;
    left: 3px;
    position: absolute;
    top: 15px;
    width: 10px
}

.checkbox span:hover:after {
    border-color: #999
}

.checkbox input {
    display: none
}

    .checkbox input + span:after {
        content: "\A";
        white-space: pre;
    }

    .checkbox input:checked + span:after {
        -webkit-animation: check .2s;
        -moz-animation: check .2s;
        -o-animation: check .2s;
        animation: check .2s;
        border-color: #555
    }

    .checkbox input:checked + .default:after {
        border-color: #444
    }

    .checkbox input:checked + .primary:after {
        border-color: #fa0505;
    }

    .checkbox input:checked + .success:after {
        border-color: #8bc34a
    }

    .checkbox input:checked + .info:after {
        border-color: #3de0f5
    }

    .checkbox input:checked + .warning:after {
        border-color: #FFC107
    }

    .checkbox input:checked + .danger:after {
        border-color: #f44336
    }

.loader {
    left: 50%;
    top: 50%;
    width: 35px;
    height: 35px;
    margin: 0 15px 0px 15px;
    border-radius: 50%;
    border: 1px solid transparent;
    border-top-color: #fa0505;
    -webkit-animation: spin 3s linear infinite;
    animation: spin 3s linear infinite;
}

    .loader:before {
        content: "";
        position: absolute;
        top: 2px;
        left: 2px;
        right: 2px;
        bottom: 2px;
        border-radius: 50%;
        border: 1px solid transparent;
        border-top-color: #707070;
        -webkit-animation: spin 2.5s linear infinite;
        animation: spin 2.5s linear infinite;
    }

    .loader:after {
        content: "";
        position: absolute;
        top: 5px;
        left: 5px;
        right: 5px;
        bottom: 5px;
        border-radius: 50%;
        border: 1px solid transparent;
        border-top-color: #cfcfcf;
        -webkit-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
    }

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.btn > svg {
    vertical-align: bottom;
}

.language {
    float: right;
    padding: 8px 20px 0px 0px;
}

.submitting-topic {
    width: 27px;
    padding: 2px;
    color: gray;
}

.li-language:hover::before {
    text-decoration: none;
}

.li-language {
    padding: 0px 10px;
    color: black;
    font-weight: normal;
    line-height: .125rem;
    font-size: 14px;
}

a < .li-language:hover {
    text-decoration: none;
    color: #fa0505;
}

.language-flag {
    width: 30px;
}


.flag {
    width: 40px;
}

.email-info {
    font-size: 16px;
    font-weight: 500;
    color: #961212;
}



.preloader-container {
    margin: 300px !important;
}

.preloader-element {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 150px;
    height: 150px;
    margin: 100px 0px 100px -75px;
    border-radius: 50%;
    border: 2px solid transparent;
    border-top-color: #fa0505;
    -webkit-animation: spin 3s linear infinite;
    animation: spin 3s linear infinite;
}

    .preloader-element:before {
        content: "";
        position: absolute;
        top: 5px;
        left: 5px;
        right: 5px;
        bottom: 5px;
        border-radius: 50%;
        border: 2px solid transparent;
        border-top-color: #616161;
        -webkit-animation: spin 4s linear infinite;
        animation: spin 4s linear infinite;
    }

    .preloader-element:after {
        content: "";
        position: absolute;
        top: 15px;
        left: 15px;
        right: 15px;
        bottom: 15px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: #545454;
        -webkit-animation: spin 3.5s linear infinite;
        animation: spin 3.5s linear infinite;
    }

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.btn-secondary:disabled {
    cursor: not-allowed !important;
    background-color: #eaeaea !important;
    color: #595959 !important;
}

.subtext {
    font-size: 13px;
    color: #666;
    padding: 5px;
    font-style: italic;
}

.subtext-number {
    font-style: italic;
    font-size: 11px;
    text-align: right;
    color: #333;
    vertical-align: super;
    font-weight: inherit;
}

.f-w-600 {
    font-weight: 600;
}


.reference {
    position: relative;
    padding: 2px 7px;
    font-size: 14px;
    margin-right: 10px;
    color: #575757;
}

.popout-menu {
    position: absolute;
    visibility: hidden;
    left: 100%;
    background: white;
    font-size: 14px;
    color: black;
    padding: 5px;
    width: 400px;
    border: 1px solid #888;
    border-radius: 5px;
    margin-left: -420px;
    z-index: 1000;
    margin-top: -5px;
}

.reference:hover > .popout-menu,
.reference:focus > .popout-menu,
.reference:focus-within > .popout-menu {
    visibility: visible;
}

#textarea-mbmedien-optout {
    font-size: 14px;
}

.dsgvo-description {
    font-size: 11px;
    font-weight: 600;
}

    .dsgvo-description > .col-lg-4 > svg {
        width: 23px !important;
    }


    .dsgvo-description > .col-lg-4.subscribe > svg {
        fill: rgb(0, 204, 79);
    }

    .dsgvo-description > .col-lg-4.opt-in > svg {
        fill: rgb(0, 204, 79);
    }


    .dsgvo-description > .col-lg-4.neutral > svg {
        fill: rgb(201, 161, 48);
    }

    .dsgvo-description > .col-lg-4.unsubscribe > svg {
        fill: rgb(239, 42, 16);
    }


    .dsgvo-description > .col-lg-4.opt-out > svg {
        fill: rgb(239, 42, 16);
    }

    .dsgvo-description > .col-lg-12 > h6 {
        font-size: 14px;
        color: #595959;
    }


.dsgvo-group {
    background: #fafafa;
    margin: 25px 0px 15px 0px;
    padding: 10px 20px 10px 20px !important;
    padding-left: 10px;
    color: #333;
    border-bottom: 1px solid #d9d9d9;
    border-top: 1px solid #d9d9d9;
}

.f-s-revert {
    font-size: small !important;
}



.no-padding {
    padding: 0px !important;
}

.site-tab {
}

    .site-tab #tabs {
        background: #007b5e;
        color: #eee;
    }

        .site-tab #tabs h6.section-title {
            color: #eee;
        }

        .site-tab #tabs .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
            color: #fa0505;
            background-color: transparent;
            border-color: none !important;
            border-bottom: 3px solid !important;
            font-size: 16px;
            font-weight: bold;
        }

    .site-tab .nav-link {
        border: 1px solid transparent;
        border-top-left-radius: .25rem;
        border-top-right-radius: .25rem;
        color: #fa0505;
        font-size: 16px;
        font-weight: 600;
    }

        .site-tab .nav-link:hover {
            border: none;
        }

    .site-tab thead {
        background: #f3f3f3;
        color: #333;
    }

    .site-tab a {
        text-decoration: none;
        color: #333;
        font-weight: 600;
    }

.btn-contact-form {
    color: #373737 !important;
    border: 1px solid #373737 !important;
    border-radius: 2px !important;
}